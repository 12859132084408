import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import React from "react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import pencil from "../../../assets/jsons/pencil.json";
import "./Testimonial.scss";

const Testimonial = () => {
  return (
    <section className="testimonial__container">
      <h1>Parents' Opinion</h1>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1.5,
          },
          960: {
            slidesPerView: 2,
          },
          1280: {
            slidesPerView: 2.5,
          },
          1600: {
            slidesPerView: 3,
          },
          1920: {
            slidesPerView: 4,
          },
          2560: {
            slidesPerView: 5,
          },
          3840: {
            slidesPerView: 6,
          },
          7680: {
            slidesPerView: 7,
          },
        }}
        centeredSlides={true}
        className="testimonial__swiper"
        coverflowEffect={{
          depth: 250,
          modifier: 1,
          rotate: 0,
          slideShadows: true,
          stretch: -100,
        }}
        effect={"coverflow"}
        grabCursor={true}
        loop={true}
        modules={[EffectCoverflow, Navigation, Pagination]}
        navigation={{
          nextEl: ".testimonial__navigation--next",
          prevEl: ".testimonial__navigation--prev",
        }}
        pagination={true}
      >
        <SwiperSlide className="testimonial__swiper-slide">
          <div className="testimonial__content">
            <div className="testimonial__title">
              <img
                alt=""
                src="https://swiperjs.com/demos/images/nature-1.jpg"
              />
              <div className="testimonial__subtitle testimonial__subtitle--daycare">
                <h2>Dedi corbuser</h2>
                <p>Adrian's Parents, Daycare</p>
              </div>
            </div>
            <div className="testimonial__description">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
                qui porro ut natus odio debitis! Aut earum error optio nostrum?
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimonial__swiper-slide">
          <div className="testimonial__content">
            <div className="testimonial__title">
              <img
                alt=""
                src="https://swiperjs.com/demos/images/nature-2.jpg"
              />
              <div className="testimonial__subtitle testimonial__subtitle--catering">
                <h2>Rafi Amad</h2>
                <p>Rendi's Parents, Catering</p>
              </div>
            </div>
            <div className="testimonial__description">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
                qui porro ut natus odio debitis! Aut earum error optio nostrum?
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimonial__swiper-slide">
          <div className="testimonial__content">
            <div className="testimonial__title">
              <img
                alt=""
                src="https://swiperjs.com/demos/images/nature-3.jpg"
              />
              <div className="testimonial__subtitle testimonial__subtitle--learning-center">
                <h2>Budi Arie</h2>
                <p>Setiadi's Parents, Learning Center</p>
              </div>
            </div>
            <div className="testimonial__description">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
                qui porro ut natus odio debitis! Aut earum error optio nostrum?
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimonial__swiper-slide">
          <div className="testimonial__content">
            <div className="testimonial__title">
              <img
                alt=""
                src="https://swiperjs.com/demos/images/nature-4.jpg"
              />
              <div className="testimonial__subtitle testimonial__subtitle--home-educare">
                <h2>Ahog</h2>
                <p>Stefano's Parents, Home Educare</p>
              </div>
            </div>
            <div className="testimonial__description">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
                qui porro ut natus odio debitis! Aut earum error optio nostrum?
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimonial__swiper-slide">
          <div className="testimonial__content">
            <div className="testimonial__title">
              <img
                alt=""
                src="https://swiperjs.com/demos/images/nature-5.jpg"
              />
              <div className="testimonial__subtitle testimonial__subtitle--preschool">
                <h2>Windah</h2>
                <p>Basudara's Parents, Preschool</p>
              </div>
            </div>
            <div className="testimonial__description">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
                qui porro ut natus odio debitis! Aut earum error optio nostrum?
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimonial__swiper-slide">
          <div className="testimonial__content">
            <div className="testimonial__title">
              <img
                alt=""
                src="https://swiperjs.com/demos/images/nature-5.jpg"
              />
              <div className="testimonial__subtitle testimonial__subtitle--preschool">
                <h2>Speed</h2>
                <p>IShow's Parents, Preschool</p>
              </div>
            </div>
            <div className="testimonial__description">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem
                qui porro ut natus odio debitis! Aut earum error optio nostrum?
              </p>
            </div>
          </div>
        </SwiperSlide>
        <button className="testimonial__navigation testimonial__navigation--next">
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
        <button className="testimonial__navigation testimonial__navigation--prev">
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      </Swiper>
      <Lottie
        className="testimonial__animation"
        animationData={pencil}
        loop={true}
      />
    </section>
  );
};

export default Testimonial;

import { createTheme } from "@mui/material";
import { globalTheme } from "./globalTheme";

export const lightTheme = createTheme({
  ...globalTheme,
  palette: {
    action: {
      focus: "rgba(0, 0, 0, 0.15)",
      selected: "rgba(0, 0, 0, 0.2)",
      hover: "rgba(0, 0, 0, 0.1)",
    },
    mode: "light",
    primary: {
      main: "hsla(193, 86%, 40%, 1)",
      light: "hsla(193, 86%, 40%, 1)",
      dark: "hsla(193, 86%, 40%, 1)",
    },
    secondary: {
      main: "hsla(337, 85%, 51%, 1)",
      light: "hsla(337, 85%, 51%, 1)",
      dark: "hsla(337, 85%, 51%, 1)",
    },
  },
});

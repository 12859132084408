import * as z from "zod";

export const registerSchema = z
  .object({
    confirm_password: z.string(),
    email: z
      .string()
      .min(1, { message: "Email is required" })
      .email({ message: "Invalid format" }),
    first_name: z.string().min(1, { message: "First Name is required" }),
    last_name: z.string(),
    password: z.string().min(1, { message: "Password is required" }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirm_password"],
  });

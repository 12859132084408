import * as z from "zod";

export const relationshipFormSchema = z.object({
  sibling: z.array(
    z.object({
      name: z.string().min(1, { message: "Name is required" }),
      dob: z.date({
        message: "Sibling dob is required",
      }),
    })
  ),
  relative: z.array(
    z.object({
      name: z.string().min(1, { message: "Name is required" }),
      relationship: z.string().min(1, { message: "Relationship is required" }),
    })
  ),
});

import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/pictures/logo.png";
import Hamburger from "../Hamburger";
import MyButton from "../MyButton";
import "./Navbar.scss";

const Navbar = () => {
  const [isChecked, setIsChecked] = useState(false);
  const hamburgerTimeline = useRef();
  const navbarTimeline = useRef();

  useGSAP(() => {
    navbarTimeline.current = gsap
      .timeline()
      .from(".navbar--landscape img", {
        duration: 0.5,
        opacity: 0,
        y: -30,
      })
      .from(".navbar--landscape ul li", {
        duration: 0.3,
        ease: "power1.out",
        opacity: 0,
        stagger: 0.15,
        y: -15,
      });

    hamburgerTimeline.current = gsap
      .timeline()
      .to(".navbar__overlay", {
        duration: 0.1,
        opacity: 0.1,
      })
      .to(
        ".navbar__drawer",
        {
          duration: 0.2,
          ease: "power1.out",
          opacity: 1,
          stagger: 0.15,
          transform: "translateX(0%)",
        },
        "0"
      )
      .from(
        ".navbar--portrait ul li",
        {
          duration: 0.2,
          ease: "power1.out",
          opacity: 0,
          stagger: 0.05,
          x: 15,
        },
        ">-0.1"
      );

    gsap.to(".navbar--landscape", {
      background: "hsla(48, 95%, 95%, 1)",
      duration: 0.2,
      scrollTrigger: {
        end: "bottom top",
        start: "bottom top",
        toggleActions: "play none none reverse",
        trigger: ".navbar--landscape",
      },
    });

    gsap.to(".navbar--portrait", {
      background: "hsla(48, 95%, 95%, 1)",
      duration: 0.2,
      scrollTrigger: {
        end: "bottom top",
        start: "bottom top",
        toggleActions: "play none none reverse",
        trigger: ".navbar--portrait",
      },
    });
  });

  const handleCloseNavbarDrawer = () => {
    setIsChecked(false);
  };

  useEffect(() => {
    hamburgerTimeline.current?.reversed(!isChecked);
    if (isChecked) {
      let overlay = document.getElementsByClassName("navbar__overlay");

      for (let i = 0; i < overlay.length; i++) {
        overlay[i].classList.remove("navbar__overlay--disabled");
      }
    } else {
      let overlay = document.getElementsByClassName("navbar__overlay");

      for (var i = 0; i < overlay.length; i++) {
        overlay[i].classList.add("navbar__overlay--disabled");
      }
    }

    return () => {};
  }, [isChecked]);

  return (
    <>
      <nav className="navbar navbar--landscape">
        <NavLink to="/">
          <img alt="" src={logo} />
        </NavLink>
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about">About Us</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
          <li>
            <NavLink to="/news">News</NavLink>
          </li>
          <li>
            <NavLink to="/login">
              <MyButton>Sign In</MyButton>
            </NavLink>
          </li>
        </ul>
      </nav>
      <nav className="navbar navbar--portrait">
        <NavLink to="/">
          <img alt="" src={logo} />
        </NavLink>
        <div className="navbar__hamburger-container">
          <Hamburger isChecked={isChecked} setIsChecked={setIsChecked} />
        </div>
        <div
          className="navbar__overlay"
          onClick={() => handleCloseNavbarDrawer()}
        ></div>
        <div className="navbar__drawer">
          <ul>
            <li>
              <NavLink onClick={() => handleCloseNavbarDrawer()} to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => handleCloseNavbarDrawer()} to="/about">
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => handleCloseNavbarDrawer()} to="/contact">
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => handleCloseNavbarDrawer()} to="/news">
                News
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => handleCloseNavbarDrawer()} to="/login">
                <MyButton>Sign In</MyButton>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

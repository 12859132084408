import React from "react";
import "./MyButton.scss";

const MyButton = ({ children, onClick }) => {
  return (
    <button className="my-button" onClick={onClick} type="submit">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span>{children}</span>
    </button>
  );
};

export default MyButton;

import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MyButton from "../../../components/MyButton/MyButton";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { diaperFormSchema } from "../../../schemas/visitorForm/diaperFormSchema";
import "./DiaperForm.scss";

const DiaperForm = () => {
  const { control, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      diaper_brand: "",
      diaper_procedure: "",
      is_wound: "",
    },
    resolver: zodResolver(diaperFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, diaper_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, diaper_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.diaper_form) {
      let tempKeys = Object.keys(data.diaper_form);
      let tempValues = Object.values(data.diaper_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="diaper-form__title">
        <h2 className="form__number form__number--big">04.h</h2>
        <h1>Diaper Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="diaper_brand"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Diaper Brand"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="diaper_procedure"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Diaper Procedure"
                minRows={3}
                multiline
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="is_wound"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <FormLabel error={!!error}>
                  Is your child prone to injury when wearing diapers?
                </FormLabel>
                <RadioGroup onChange={onChange} row value={value}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    sx={{ flex: 1 }}
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    sx={{ flex: 1 }}
                    value={false}
                  />
                </RadioGroup>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default DiaperForm;

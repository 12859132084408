import { createContext, useState } from "react";
import { lightTheme } from "../themes/homeTheme/lightTheme";

const HomeThemeContext = createContext();

const HomeThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(lightTheme);

  return (
    <HomeThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </HomeThemeContext.Provider>
  );
};

export { HomeThemeContextProvider };
export default HomeThemeContext;

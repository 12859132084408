import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import MyButton from "../../../components/MyButton/MyButton";
import { genderOptions } from "../../../constants/genderOptions";
import { languageOptions } from "../../../constants/languageOptions";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { childFormSchema } from "../../../schemas/visitorForm/childFormSchema";
import "./ChildForm.scss";

const ChildForm = () => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      birth_certificate: null,
      dob: null,
      full_name: "",
      gender: "",
      language: "",
      nickname: "",
      pediatrician_name: "",
      pediatrician_number: "",
      pet: "",
      registration_date: null,
    },
    resolver: zodResolver(childFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);
  const [birthCertificateImagePreview, setBirthCertificateImagePreview] =
    useState([]);
  const birthCertificateWatch = watch("birth_certificate");

  const handleNext = (payload) => {
    setData({ ...data, child_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, child_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    let fileReaders = [];
    let isCancel = false;
    let tempImagePreview = [];

    if (birthCertificateWatch) {
      Object.values(birthCertificateWatch).forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            tempImagePreview.push(result);
          }
          if (!isCancel) {
            setBirthCertificateImagePreview(tempImagePreview);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }

    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [birthCertificateWatch]);

  useEffect(() => {
    const { unsubscribe } = watch((_) => {});
    return () => unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (data.child_form) {
      let tempKeys = Object.keys(data.child_form);
      let tempValues = Object.values(data.child_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="child-form__title">
        <h2 className="form__number form__number--big">04.a</h2>
        <h1>Child Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="registration_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Registration Date"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="full_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Full Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="nickname"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nickname"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="gender"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <FormControl error={!!error} fullWidth>
                <InputLabel error={!!error}>Gender</InputLabel>
                <Select
                  inputRef={ref}
                  label="Gender"
                  onChange={onChange}
                  value={value}
                >
                  {genderOptions.map((gender, index) => {
                    return (
                      <MenuItem key={index} value={gender.id}>
                        {gender.id === "" ? (
                          <i>{gender.label}</i>
                        ) : (
                          gender.label
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="dob"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Date of Birth"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="pediatrician_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Pediatrician Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="pediatrician_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Pediatrician Number"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                slotProps={{
                  input: {
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">+62</InputAdornment>
                    ),
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="language"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <FormControl error={!!error} fullWidth>
                <InputLabel error={!!error}>Daily Language</InputLabel>
                <Select
                  inputRef={ref}
                  label="Daily Language"
                  onChange={onChange}
                  value={value}
                >
                  {languageOptions.map((language, index) => {
                    return (
                      <MenuItem key={index} value={language.id}>
                        {language.id === "" ? (
                          <i>{language.label}</i>
                        ) : (
                          language.label
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="pet"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Pet"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <div className="file-upload__box">
            <label htmlFor="birth-certificate"></label>
            {errors?.birth_certificate ||
            birthCertificateImagePreview.length <= 0 ? (
              <div className="file-upload__description">
                <p>
                  <b>Upload Birth Certificate</b>
                </p>
                <span>Max. Size 5Mb</span>
              </div>
            ) : (
              <div className="file-upload__image-container">
                {birthCertificateImagePreview.map((element, index) => {
                  return (
                    <div key={index} className="image-preview">
                      <img alt="" src={element} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <p className="file-upload__error-message">
            {errors?.birth_certificate?.message}
          </p>
          <input
            {...register("birth_certificate")}
            accept="image/*"
            hidden
            id="birth-certificate"
            type="file"
          />
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChildForm;

import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import Achievement from "./Achievement";
import CallToAction from "./CallToAction";
import Gallery from "./Gallery/Gallery";
import Hero from "./Hero";
import "./Home.scss";
import News from "./News";
import Service from "./Service";
import Tagline from "./Tagline";
import Testimonial from "./Testimonial";

const Home = () => {
  // remove later
  useEffect(() => {
    const myTimeout = setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);

    return () => clearTimeout(myTimeout);
  }, []);

  return (
    <main className="home">
      <Hero />
      <div className="spacer"></div>
      <Tagline />
      <Achievement />
      <div className="spacer"></div>
      <div className="services__wave">
        <svg preserveAspectRatio="none" viewBox="0 0 1440 320">
          <path
            fillOpacity="1"
            d="M0,128L48,138.7C96,149,192,171,288,186.7C384,203,480,213,576,218.7C672,224,768,224,864,186.7C960,149,1056,75,1152,74.7C1248,75,1344,149,1392,186.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
      <Service />
      <div className="spacer spacer--secondary"></div>
      <div className="testimonial__wave">
        <svg preserveAspectRatio="none" viewBox="0 0 1440 320">
          <path
            fillOpacity="1"
            d="M0,32L26.7,32C53.3,32,107,32,160,69.3C213.3,107,267,181,320,213.3C373.3,245,427,235,480,197.3C533.3,160,587,96,640,64C693.3,32,747,32,800,42.7C853.3,53,907,75,960,101.3C1013.3,128,1067,160,1120,160C1173.3,160,1227,128,1280,101.3C1333.3,75,1387,53,1413,42.7L1440,32L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
          ></path>
        </svg>
      </div>
      <Testimonial />
      <div className="gallery__wave">
        <svg preserveAspectRatio="none" viewBox="0 0 1440 320">
          <path
            fillOpacity="1"
            d="M0,96L40,80C80,64,160,32,240,26.7C320,21,400,43,480,69.3C560,96,640,128,720,149.3C800,171,880,181,960,160C1040,139,1120,85,1200,64C1280,43,1360,53,1400,58.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
      </div>
      <Gallery />
      <div className="spacer"></div>
      <div className="cta__wave">
        <svg preserveAspectRatio="none" viewBox="0 0 1440 320">
          <path
            fillOpacity="1"
            d="M0,288L48,256C96,224,192,160,288,138.7C384,117,480,139,576,165.3C672,192,768,224,864,197.3C960,171,1056,85,1152,74.7C1248,64,1344,128,1392,160L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
      <CallToAction />
      <div className="commitment__wave">
        <svg preserveAspectRatio="none" viewBox="0 0 1440 320">
          <path
            fillOpacity="1"
            d="M0,0L48,42.7C96,85,192,171,288,197.3C384,224,480,192,576,192C672,192,768,224,864,240C960,256,1056,256,1152,229.3C1248,203,1344,149,1392,122.7L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
      <News />
      {/* <Commitment /> */}
    </main>
  );
};

export default Home;

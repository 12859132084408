import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/pictures/logo.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__separator">
        <ul className="footer__navigation">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about">About Us</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
          <li>
            <NavLink to="/news">News</NavLink>
          </li>
        </ul>
        <div>
          <ul className="footer__information">
            <li>
              <NavLink to="/">
                <img alt="" src={logo} />
              </NavLink>
            </li>
            <li>
              <p>
                <strong>Angels & I Childcare</strong>
              </p>
            </li>
            <li>
              <p>
                Jl. Ngagel Madya Utara No.28, Baratajaya, Kec. Gubeng, Surabaya,
                Jawa Timur 60284
              </p>
            </li>
            <li>
              <p>(031) 5031811</p>
            </li>
          </ul>
          <ul className="footer__social-media">
            <li>
              <a href="https://facebook.com" rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a href="https://instagram.com" rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a href="https://youtube.com" rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li>
              <a href="https://whatsapp.com" rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <ul className="footer__privacy-policy">
        <li>
          <NavLink to="/term">Term & Condition</NavLink>
        </li>
        <li>
          <p>Copyright &copy; 2024</p>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;

import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import React from "react";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import sharpener from "../../../assets/jsons/sharpener.json";
import circleBlue from "../../../assets/svgs/circle_blue.svg";
import circleRed from "../../../assets/svgs/circle_red.svg";
import triangleRed from "../../../assets/svgs/triangle_red.svg";
import "./Hero.scss";

const Hero = () => {
  const handleClick = () => {
    gsap.to(window, {
      duration: 1,
      ease: "power3",
      scrollTo: { offsetY: 70, y: "#tagline" },
    });
  };

  useGSAP(() => {
    gsap.from(".hero__see-more", {
      duration: 1,
      ease: "power3.out",
      opacity: 0,
      scale: 0,
    });

    gsap.to(".hero__shape--circle-1", {
      duration: 3,
      ease: "none",
      repeat: -1,
      y: -10,
      yoyo: true,
    });

    gsap.to(".hero__shape--triangle-1", {
      duration: 30,
      ease: "none",
      repeat: -1,
      rotation: 360,
    });

    gsap.from(".hero__shape--circle-2", {
      duration: 3,
      ease: "none",
      scale: 0.9,
      repeat: -1,
      yoyo: true,
    });
  });

  return (
    <section className="hero__container">
      <div className="hero__overlay"></div>
      <img
        alt=""
        className="hero__shape hero__shape--circle-1"
        src={circleRed}
      />
      <img
        alt=""
        className="hero__shape hero__shape--triangle-1"
        src={triangleRed}
      />
      <img
        alt=""
        className="hero__shape hero__shape--circle-2"
        src={circleBlue}
      />
      <Lottie
        className="hero__animation hero__animation--sharpener"
        animationData={sharpener}
        loop={true}
      />
      <Swiper
        className="hero__swiper"
        effect={"fade"}
        grabCursor={true}
        loop={true}
        modules={[EffectFade, Navigation, Pagination]}
        navigation={{
          nextEl: ".hero__navigation--next",
          prevEl: ".hero__navigation--prev",
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide className="hero__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images.alphacoders.com/129/1294486.png"
            />
            <img alt="" src="https://artfiles.alphacoders.com/152/152722.jpg" />
          </picture>
        </SwiperSlide>
        <SwiperSlide className="hero__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images.alphacoders.com/781/781392.png"
            />
            <img alt="" src="https://images4.alphacoders.com/830/830210.png" />
          </picture>
        </SwiperSlide>
        <SwiperSlide className="hero__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images4.alphacoders.com/105/1056216.png"
            />
            <img alt="" src="https://images4.alphacoders.com/125/1250516.jpg" />
          </picture>
        </SwiperSlide>
        <SwiperSlide className="hero__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images7.alphacoders.com/115/1155199.jpg"
            />
            <img alt="" src="https://images2.alphacoders.com/556/556384.png" />
          </picture>
        </SwiperSlide>
        <button className="hero__navigation hero__navigation--next">
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
        <button className="hero__navigation hero__navigation--prev">
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      </Swiper>
      <button className="hero__see-more" onClick={handleClick}>
        See More
        <span>
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>
    </section>
  );
};

export default Hero;

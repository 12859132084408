import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Lottie from "lottie-react";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import daycare from "../../../assets/jsons/daycare.json";
import home_educare from "../../../assets/jsons/home_educare.json";
import learning_center from "../../../assets/jsons/learning_center.json";
import preschool from "../../../assets/jsons/preschool.json";
import Empty from "../../../components/Empty";
import MyButton from "../../../components/MyButton/MyButton";
import { mainCategory } from "../../../constants/mainCategory";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { choosePackageSchema } from "../../../schemas/visitorForm/choosePackageSchema";
import "./ChoosePackage.scss";
import Daycare from "./Daycare";
import HomeEducare from "./HomeEducare";
import LearningCenter from "./LearningCenter";
import Preschool from "./Preschool";

const ChoosePackage = () => {
  const { getValues, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      package: "",
    },
    resolver: zodResolver(choosePackageSchema),
  });
  const selectedPackageWatch = watch("package");
  const { data, setData, setStep } = useContext(VisitorFormContext);
  const snackbarContext = useContext(SnackbarContext);

  const handleError = (error) => {
    snackbarContext.handleOpenSnackbar(error.package.message, "error");
  };

  const handleNext = (payload) => {
    setData({ ...data, package: getValues("package"), ...payload });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = (payload) => {
    setData({ ...data, package: getValues("package"), ...payload });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    const { unsubscribe } = watch((_) => {});
    return () => unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (data.package) {
      setValue("package", data.package);
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(handleNext, handleError)}>
        <div className="choose-package__header">
          <h2 className="form__number">02</h2>
          <h1>Choose Package</h1>
        </div>
        <Grid container spacing={3}>
          <Grid
            className="choose-package__card"
            size={{ lg: 3, md: 4, sm: 6, xs: 12 }}
          >
            <Lottie
              animationData={daycare}
              className="choose-package__animation"
              loop={true}
            />
            <div className="choose-package__title">
              <h3>Daycare</h3>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
            </div>
            <div className="choose-package__pricing">
              <p>start from</p>
              <h4>Rp 1.000.000,00</h4>
              <span>/ month</span>
            </div>
            <label htmlFor="daycare"></label>
            <input
              {...register("package")}
              id="daycare"
              type="radio"
              value={mainCategory.DAYCARE}
            />
          </Grid>
          <Grid
            className="choose-package__card"
            size={{ lg: 3, md: 4, sm: 6, xs: 12 }}
          >
            <Lottie
              animationData={home_educare}
              className="choose-package__animation"
              loop={true}
            />
            <div className="choose-package__title">
              <h3>Home Educare</h3>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
            </div>
            <div className="choose-package__pricing">
              <p>start from</p>
              <h4>Rp 500.000,00</h4>
              <span>/ month</span>
            </div>
            <label htmlFor="home_educare"></label>
            <input
              {...register("package")}
              id="home_educare"
              type="radio"
              value={mainCategory.HOME_EDUCARE}
            />
          </Grid>
          <Grid
            className="choose-package__card"
            size={{ lg: 3, md: 4, sm: 6, xs: 12 }}
          >
            <Lottie
              animationData={preschool}
              className="choose-package__animation"
              loop={true}
            />
            <div className="choose-package__title">
              <h3>Preschool</h3>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
            </div>
            <div className="choose-package__pricing">
              <p>start from</p>
              <h4>Rp 1.200.000,00</h4>
              <span>/ month</span>
            </div>
            <label htmlFor="preschool"></label>
            <input
              {...register("package")}
              id="preschool"
              type="radio"
              value={mainCategory.PRESCHOOL}
            />
          </Grid>
          <Grid
            className="choose-package__card"
            size={{ lg: 3, md: 4, sm: 6, xs: 12 }}
          >
            <Lottie
              animationData={learning_center}
              className="choose-package__animation"
              loop={true}
            />
            <div className="choose-package__title">
              <h3>Learning Center</h3>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
            </div>
            <div className="choose-package__pricing">
              <p>start from</p>
              <h4>Rp 750.000,00</h4>
              <span>/ month</span>
            </div>
            <label htmlFor="learning_center"></label>
            <input
              {...register("package")}
              id="learning_center"
              type="radio"
              value={mainCategory.LEARNING_CENTER}
            />
          </Grid>
        </Grid>
      </form>
      {selectedPackageWatch !== "" && (
        <>
          <Divider sx={{ my: 3 }} />
          <div className="choose-package__form">
            {selectedPackageWatch === mainCategory.DAYCARE ? (
              <Daycare handleNext={handleNext} handlePrev={handlePrev} />
            ) : selectedPackageWatch === mainCategory.HOME_EDUCARE ? (
              <HomeEducare handleNext={handleNext} handlePrev={handlePrev} />
            ) : selectedPackageWatch === mainCategory.PRESCHOOL ? (
              <>
                <Preschool />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: 3 }}
                >
                  <MyButton
                    onClick={() => handlePrev({ package: "preschool" })}
                  >
                    Prev
                  </MyButton>
                  <MyButton
                    onClick={() => handleNext({ package: "preschool" })}
                  >
                    Next
                  </MyButton>
                </Stack>
              </>
            ) : selectedPackageWatch === mainCategory.LEARNING_CENTER ? (
              <LearningCenter handleNext={handleNext} handlePrev={handlePrev} />
            ) : (
              <Empty />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ChoosePackage;

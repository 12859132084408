import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "./Gallery.scss";

const Gallery = () => {
  useGSAP(() => {
    ScrollTrigger.batch(".gallery__masonry img", {
      end: "bottom center",
      start: "top 90%",
      once: true,
      onEnter: (batch) => {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 80 },
          {
            duration: 0.5,
            opacity: 1,
            stagger: 0.1,
            y: 0,
          }
        );
      },
    });
  });

  return (
    <section className="gallery__container">
      <h1>Gallery</h1>
      <ResponsiveMasonry
        className="gallery__masonry"
        columnsCountBreakPoints={{ 320: 2, 720: 3, 1280: 4 }}
      >
        <Masonry gutter="8px">
          <img alt="" src="https://picsum.photos/200/300?image=1050" />
          <img alt="" src="https://picsum.photos/400/400?image=1039" />
          <img alt="" src="https://picsum.photos/400/400?image=1080" />
          <img alt="" src="https://picsum.photos/200/200?image=997" />
          <img alt="" src="https://picsum.photos/500/400?image=287" />
          <img alt="" src="https://picsum.photos/400/500?image=955" />
          <img alt="" src="https://picsum.photos/200/200?image=997" />
          <img alt="" src="https://picsum.photos/300/300?image=110" />
        </Masonry>
      </ResponsiveMasonry>
    </section>
  );
};

export default Gallery;

import React from "react";
import "./MyButtonInverted.scss";

const MyButtonInverted = ({ children, onClick }) => {
  return (
    <button className="my-button-inverted" onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span>{children}</span>
    </button>
  );
};

export default MyButtonInverted;

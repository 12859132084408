import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import MyButton from "../../../components/MyButton/MyButton";
import { religionOptions } from "../../../constants/religionOptions";
import { salaryOptions } from "../../../constants/salaryOptions";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { motherFormSchema } from "../../../schemas/visitorForm/motherFormSchema";
import "./MotherForm.scss";

const MotherForm = () => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      employment_duration: "",
      full_name: "",
      home_address: "",
      job_title: "",
      ktp: null,
      office_address: "",
      office_name: "",
      office_phone_number: "",
      phone_number: "",
      religion: "",
      salary: "",
    },
    resolver: zodResolver(motherFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, mother_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, mother_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.mother_form) {
      let tempKeys = Object.keys(data.mother_form);
      let tempValues = Object.values(data.mother_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="mother-form__title">
        <h2 className="form__number form__number--big">04.c</h2>
        <h1>Mother Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="full_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Full Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="home_address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Address"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="phone_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Phone Number"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                slotProps={{
                  input: {
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">+62</InputAdornment>
                    ),
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="office_phone_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Office Phone Number"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                slotProps={{
                  input: {
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">+62</InputAdornment>
                    ),
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="office_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Office Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="office_address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Office Address"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="job_title"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Job Title"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="employment_duration"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Employment Duration"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="salary"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <FormControl error={!!error} fullWidth>
                <InputLabel error={!!error}>Estimated Salary</InputLabel>
                <Select
                  inputRef={ref}
                  label="Estimated Salary"
                  onChange={onChange}
                  value={value}
                >
                  {salaryOptions.map((salary, index) => {
                    return (
                      <MenuItem key={index} value={salary.id}>
                        {salary.id === "" ? (
                          <i>{salary.label}</i>
                        ) : (
                          salary.label
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="religion"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <FormControl error={!!error} fullWidth>
                <InputLabel error={!!error}>Religion</InputLabel>
                <Select
                  inputRef={ref}
                  label="Religion"
                  onChange={onChange}
                  value={value}
                >
                  {religionOptions.map((religion, index) => {
                    return (
                      <MenuItem key={index} value={religion.id}>
                        {religion.id === "" ? (
                          <i>{religion.label}</i>
                        ) : (
                          religion.label
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <label htmlFor="ktp">
            <h3>Upload KTP</h3>
            <br />
          </label>
          <input {...register("ktp")} accept="image/*" id="ktp" type="file" />
          <p className="file-upload__error-message">{errors?.ktp?.message}</p>
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default MotherForm;

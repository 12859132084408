import * as z from "zod";

const maxFileSizeMB = 5;
const allowedFileExtensions = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const childFormSchema = z.object({
  birth_certificate: z
    .instanceof(FileList)
    .transform((files) => Array.from(files))
    .refine((files) => files.length > 0, {
      message: "At least one file is required",
    })
    .refine(
      (files) =>
        files.every((file) => file.size <= maxFileSizeMB * 1024 * 1024),
      {
        message: "File must be 5MB or less",
      }
    )
    .refine(
      (files) =>
        files.every((file) => allowedFileExtensions.includes(file.type)),
      {
        message: "Only .jpeg, .jpg, .png, and .webp files are allowed",
      }
    ),
  dob: z.date({
    message: "Child dob is required",
  }),
  full_name: z.string().min(1, { message: "Full name is required" }),
  gender: z.string().min(1, { message: "Gender is required" }),
  language: z.string().min(1, { message: "Daily language is required" }),
  nickname: z.string().min(1, { message: "Nickname is required" }),
  pediatrician_name: z
    .string()
    .min(1, { message: "Pediatrician name is required" }),
  pediatrician_number: z
    .string()
    .min(1, { message: "Pediatrician number is required" }),
  pet: z.string(),
  registration_date: z.date({
    message: "Registration date is required",
  }),
});

import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MyButton from "../../../components/MyButton/MyButton";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { socialEmotionalFormSchema } from "../../../schemas/visitorForm/socialEmotionalFormSchema";
import "./SocialEmotionalForm.scss";

const SocialEmotionalForm = () => {
  const { control, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      child_activity: "",
      child_behavior: "",
      child_characteristic: "",
      child_separated: "",
      child_service: "",
      child_social: "",
      child_talent: "",
      child_toy: "",
      child_trauma: "",
    },
    resolver: zodResolver(socialEmotionalFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, diaper_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, diaper_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.diaper_form) {
      let tempKeys = Object.keys(data.diaper_form);
      let tempValues = Object.values(data.diaper_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form className="social-emotional-form" onSubmit={handleSubmit(handleNext)}>
      <div className="social-emotional-form__title">
        <h2 className="form__number form__number--big">04.i</h2>
        <h1>Social Emotional Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={12}>
          <h3>Explain Your Child Characteristic</h3>
          <Controller
            control={control}
            name="child_characteristic"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>Child Talent</h3>
          <Controller
            control={control}
            name="child_talent"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Do your child shows different behavior when tired, hungry, or
            energetic ? (such as wiping eyes or pulling ear)
          </h3>
          <Controller
            control={control}
            name="child_behavior"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Do your child used to get separated from you? If so, please describe
          </h3>
          <Controller
            control={control}
            name="child_separated"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Do your child have specific fear or trauma ? if so, please describe
          </h3>
          <Controller
            control={control}
            name="child_trauma"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Do your child have specific toys or favourite items ? if so, please
            describe
          </h3>
          <Controller
            control={control}
            name="child_toy"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Do your child like to spend time with other child ? if so, please
            describe
          </h3>
          <Controller
            control={control}
            name="child_social"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>Child favorite activity</h3>
          <Controller
            control={control}
            name="child_activity"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Do your child ever been to other daycare service ? if so, please
            describe
          </h3>
          <Controller
            control={control}
            name="child_service"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default SocialEmotionalForm;

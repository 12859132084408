import * as z from "zod";

const maxFileSizeMB = 5;
const allowedFileExtensions = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const emergencyFormSchema = z.object({
  emergency_person: z.array(
    z.object({
      ktp: z
        .instanceof(FileList)
        .transform((files) => Array.from(files))
        .refine((files) => files.length > 0, {
          message: "At least one file is required",
        })
        .refine(
          (files) =>
            files.every((file) => file.size <= maxFileSizeMB * 1024 * 1024),
          {
            message: "File must be 5MB or less",
          }
        )
        .refine(
          (files) =>
            files.every((file) => allowedFileExtensions.includes(file.type)),
          {
            message: "Only .jpeg, .jpg, .png, and .webp files are allowed",
          }
        ),
      name: z.string().min(1, { message: "Name is required" }),
      office_phone_number: z
        .string()
        .min(1, { message: "Office phone number is required" }),
      phone_number: z.string().min(1, { message: "Phone number is required" }),
      relationship: z.string().min(1, { message: "Relationship is required" }),
    })
  ),
  pick_up_person: z.array(
    z.object({
      ktp: z
        .instanceof(FileList)
        .transform((files) => Array.from(files))
        .refine((files) => files.length > 0, {
          message: "At least one file is required",
        })
        .refine(
          (files) =>
            files.every((file) => file.size <= maxFileSizeMB * 1024 * 1024),
          {
            message: "File must be 5MB or less",
          }
        )
        .refine(
          (files) =>
            files.every((file) => allowedFileExtensions.includes(file.type)),
          {
            message: "Only .jpeg, .jpg, .png, and .webp files are allowed",
          }
        ),
      name: z.string().min(1, { message: "Name is required" }),
      phone_number: z.string().min(1, { message: "Phone number is required" }),
    })
  ),
});

import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React from "react";
import circleBlue from "../../../assets/svgs/circle_blue.svg";
import circleRed from "../../../assets/svgs/circle_red.svg";
import starYellow from "../../../assets/svgs/star_yellow.svg";
import triangleRed from "../../../assets/svgs/triangle_red.svg";
import "./Title.scss";

const Title = () => {
  useGSAP(() => {
    gsap.to(".about-title__shape--circle-1", {
      duration: 3,
      ease: "none",
      repeat: -1,
      y: -10,
      yoyo: true,
    });

    gsap.to(".about-title__shape--star-1", {
      duration: 30,
      ease: "none",
      repeat: -1,
      rotation: -360,
    });

    gsap.to(".about-title__shape--triangle-1", {
      duration: 30,
      ease: "none",
      repeat: -1,
      rotation: 360,
    });

    gsap.from(".about-title__shape--circle-2", {
      duration: 3,
      ease: "none",
      scale: 0.9,
      repeat: -1,
      yoyo: true,
    });
  });

  return (
    <section className="about-title__container">
      <img
        alt=""
        className="about-title__background"
        src="https://images.alphacoders.com/129/1294486.png"
      />
      <div className="about-title__filter"></div>
      <h1>About Us</h1>
      <div className="about-title__overlay"></div>
      <img
        alt=""
        className="about-title__shape about-title__shape--circle-1"
        src={circleRed}
      />
      <img
        alt=""
        className="about-title__shape about-title__shape--star-1"
        src={starYellow}
      />
      <img
        alt=""
        className="about-title__shape about-title__shape--triangle-1"
        src={triangleRed}
      />
      <img
        alt=""
        className="about-title__shape about-title__shape--circle-2"
        src={circleBlue}
      />
    </section>
  );
};

export default Title;

import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import MyButton from "../../../../components/MyButton/MyButton";
import MyButtonInverted from "../../../../components/MyButtonInverted";
import { daycareDurationOptions } from "../../../../constants/daycare/daycareDurationOptions";
import { daycareStartTimeOptions } from "../../../../constants/daycare/daycareStartTimeOptions";
import { dayNames } from "../../../../constants/dayNames";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import VisitorFormContext from "../../../../contexts/VisitorFormContextProvider";
import { daycareScheduleSchema } from "../../../../schemas/visitorForm/daycareScheduleSchema";
import "./Daycare.scss";

const initialValues = [
  {
    duration: "",
    is_checked: false,
    start_time: "",
  },
  {
    duration: "",
    is_checked: false,
    start_time: "",
  },
  {
    duration: "",
    is_checked: false,
    start_time: "",
  },
  {
    duration: "",
    is_checked: false,
    start_time: "",
  },
  {
    duration: "",
    is_checked: false,
    start_time: "",
  },
  {
    duration: "",
    is_checked: false,
    start_time: "",
  },
  {
    duration: "",
    is_checked: false,
    start_time: "",
  },
];

const Daycare = ({ handleNext, handlePrev }) => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      daycare_schedule: initialValues,
    },
    resolver: zodResolver(daycareScheduleSchema),
  });
  const { fields } = useFieldArray({
    control,
    name: "daycare_schedule",
  });
  const { data } = useContext(VisitorFormContext);
  const daycareScheduleWatch = watch("daycare_schedule");
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const handleChooseDaycareSchedule = (payload) => {
    handleNext(payload);
  };

  const handleError = (error) => {
    let tempError = error.daycare_schedule.day_list;

    if (tempError) {
      snackbarContext.handleOpenSnackbar(tempError.message, "error");
    }
  };

  useEffect(() => {
    const { unsubscribe } = watch((_) => {});
    return () => unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (data.daycare_schedule) {
      setValue("daycare_schedule", data.daycare_schedule);
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="daycare__title">
        <h2 className="form__number  form__number--big">02.a</h2>
        <h1>Choose Schedule</h1>
        <MyButtonInverted
          onClick={() => {
            dialogContext.handleOpenDialog(
              () => reset(),
              "Are you sure want to reset?",
              "Reset Confirmation"
            );
          }}
        >
          Reset
        </MyButtonInverted>
      </div>
      <form onSubmit={handleSubmit(handleChooseDaycareSchedule, handleError)}>
        <Grid container spacing={3}>
          {fields.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <Grid className="daycare__card" container size={12} spacing={3}>
                  <label htmlFor={dayNames[index]}></label>
                  <input
                    {...register(`daycare_schedule.${index}.is_checked`, {
                      onChange: (e) => {
                        if (isSubmitted) {
                          trigger([
                            `daycare_schedule.${index}.duration`,
                            `daycare_schedule.${index}.start_time`,
                          ]);
                        }
                        return e.target.value;
                      },
                    })}
                    id={dayNames[index]}
                    type="checkbox"
                  />
                  <Grid size={{ sm: 4, xs: 12 }}>
                    <h2 className="daycare__day">{dayNames[index]}</h2>
                  </Grid>
                  <Grid size={{ sm: 4, xs: 12 }}>
                    <Controller
                      control={control}
                      name={`daycare_schedule.${index}.duration`}
                      render={({
                        field: { onChange, ref, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <InputLabel error={!!error}>Duration</InputLabel>
                          <Select
                            inputRef={ref}
                            label="Duration"
                            onChange={(e) => {
                              onChange(e);

                              if (e.target.value !== "2") {
                                setValue(
                                  `daycare_schedule.${index}.start_time`,
                                  ""
                                );
                              }

                              if (isSubmitted) {
                                trigger(`daycare_schedule.${index}.start_time`);
                              }
                            }}
                            value={value}
                          >
                            {daycareDurationOptions.map((duration, index) => {
                              return (
                                <MenuItem key={index} value={duration.id}>
                                  {duration.id === "" ? (
                                    <i>{duration.label}</i>
                                  ) : (
                                    duration.label
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid size={{ sm: 4, xs: 12 }}>
                    <Controller
                      control={control}
                      name={`daycare_schedule.${index}.start_time`}
                      render={({
                        field: { onChange, ref, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <InputLabel error={!!error}>Start Time</InputLabel>
                          <Select
                            disabled={
                              daycareScheduleWatch[index].duration !== "2"
                            }
                            inputRef={ref}
                            label="Start Time"
                            onChange={onChange}
                            value={value}
                          >
                            {daycareStartTimeOptions.map((startTime, index) => {
                              return (
                                <MenuItem key={index} value={startTime.id}>
                                  {startTime.id === "" ? (
                                    <i>{startTime.label}</i>
                                  ) : (
                                    startTime.label
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
          <MyButton
            onClick={() =>
              handlePrev({ daycare_schedule: getValues("daycare_schedule") })
            }
          >
            Prev
          </MyButton>
          <MyButton>Next</MyButton>
        </Stack>
      </form>
    </>
  );
};

export default Daycare;

import { faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Location.scss";

const Location = () => {
  return (
    <section className="location__container">
      <h1>Our Locations</h1>
      <div className="location__content">
        <div>
          <iframe
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.580890712901!2d112.75936829999999!3d-7.2884324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbb5b3c936ad%3A0x503a6c305bfc905a!2sJl.%20Ngagel%20Madya%20Utara%20No.28%2C%20RW.01%2C%20Baratajaya%2C%20Kec.%20Gubeng%2C%20Surabaya%2C%20Jawa%20Timur%2060284!5e0!3m2!1sid!2sid!4v1727252527631!5m2!1sid!2sid"
            title="main"
          ></iframe>
          <h2>Main Branch</h2>
          <div className="location__description">
            <div>
              <FontAwesomeIcon className="location__icon" icon={faHome} />
              <p>
                Jl. Ngagel Madya Utara No.28, Baratajaya, Kec. Gubeng, Surabaya,
                Jawa Timur 60284
              </p>
            </div>
            <div>
              <FontAwesomeIcon className="location__icon" icon={faPhone} />
              <p>(031) 5031811</p>
            </div>
          </div>
        </div>
        <div>
          <iframe
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.6617319421125!2d112.69684799999999!3d-7.2792758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fc19fd9fb663%3A0xa454f96aa9d2d136!2sJl.%20Raya%20Darmo%20Baru%20Barat%20No.64%2C%20Sonokwijenan%2C%20Kec.%20Sukomanunggal%2C%20Surabaya%2C%20Jawa%20Timur%2060226!5e0!3m2!1sid!2sid!4v1727252598734!5m2!1sid!2sid"
            title="branch"
          ></iframe>
          <h2>West Branch</h2>
          <div className="location__description">
            <div>
              <FontAwesomeIcon className="location__icon" icon={faHome} />
              <p>
                Jl. Raya Darmo Baru Barat No.64, Sonokwijenan, Kec.
                Sukomanunggal, Surabaya, Jawa Timur 60226
              </p>
            </div>
            <div>
              <FontAwesomeIcon className="location__icon" icon={faPhone} />
              <p>(031) 5031811</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;

import * as z from "zod";

export const socialEmotionalFormSchema = z.object({
  child_activity: z.string().min(1, { message: "Child activity is required" }),
  child_behavior: z.string().min(1, { message: "Child behavior is required" }),
  child_characteristic: z
    .string()
    .min(1, { message: "Child characteristic is required" }),
  child_separated: z
    .string()
    .min(1, { message: "Child separated is required" }),
  child_service: z.string().min(1, { message: "Child service is required" }),
  child_social: z.string().min(1, { message: "Child social is required" }),
  child_talent: z.string().min(1, { message: "Child talent is required" }),
  child_toy: z.string().min(1, { message: "Child toy is required" }),
  child_trauma: z.string().min(1, { message: "Child trauma is required" }),
});

import * as z from "zod";

export const daycareScheduleSchema = z.object({
  daycare_schedule: z
    .array(
      z
        .object({
          duration: z.string(),
          is_checked: z.boolean(),
          start_time: z.string(),
        })
        .superRefine((val, ctx) => {
          if (val.is_checked) {
            if (!val.duration) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Duration is required",
                path: ["duration"],
              });
            }
            if (val.duration === "2" && !val.start_time) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Start time is required",
                path: ["start_time"],
              });
            }
          }
          return true;
        })
    )
    .superRefine((val, ctx) => {
      let isValid = false;

      for (let i = 0; i < val.length; i++) {
        const element = val[i];

        if (element.is_checked === true) {
          isValid = true;
        }
      }

      if (!isValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Need at least 1 day",
          path: ["day_list"],
        });
      }

      return isValid;
    }),
});

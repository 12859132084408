export const religionOptions = [
  { id: "", label: "None" },
  { id: "1", label: "Islam" },
  { id: "2", label: "Protestantism" },
  { id: "3", label: "Catholicism" },
  { id: "4", label: "Hinduism" },
  { id: "5", label: "Buddhism" },
  { id: "6", label: "Confucianism" },
  { id: "7", label: "Other" },
];

import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MyButton from "../../../components/MyButton/MyButton";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { visitorFormSchema } from "../../../schemas/visitorForm/visitorFormSchema";
import "./VisitorForm.scss";

const VisitorForm = () => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      child_dob: null,
      child_name: "",
      father_name: "",
      mother_name: "",
    },
    resolver: zodResolver(visitorFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, visitor_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (data.visitor_form) {
      let tempKeys = Object.keys(data.visitor_form);
      let tempValues = Object.values(data.visitor_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="visitor-form__title">
        <h3 className="form__number">01</h3>
        <h1>Visitor Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="father_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Father Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="mother_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Mother Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="child_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Child Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="child_dob"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Date of Birth"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="flex-end">
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default VisitorForm;

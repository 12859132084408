import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TimePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MyButton from "../../../components/MyButton/MyButton";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { foodFormSchema } from "../../../schemas/visitorForm/foodFormSchema";
import "./FoodForm.scss";

const FoodForm = () => {
  const { control, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      allergy: "",
      breast_milk_position: "",
      breast_milk_schedule: null,
      burp_position: "",
      disliked_food: "",
      eat_schedule: null,
      favorite_food: "",
      is_breast_milk: "",
      is_pacifier: "",
      is_planning_breast_milk: "",
      is_solid_food: "",
      supplement: "",
    },
    resolver: zodResolver(foodFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, food_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, food_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.food_form) {
      let tempKeys = Object.keys(data.food_form);
      let tempValues = Object.values(data.food_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="food-form__title">
        <h2 className="form__number form__number--big">04.f</h2>
        <h1>Food Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Controller
            control={control}
            name="is_breast_milk"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <FormLabel error={!!error}>
                  Is the child still consuming breast milk?
                </FormLabel>
                <RadioGroup onChange={onChange} row value={value}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    sx={{ flex: 1 }}
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    sx={{ flex: 1 }}
                    value={false}
                  />
                </RadioGroup>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="is_planning_breast_milk"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <FormLabel error={!!error}>
                  Are you planning to breastfeed?
                </FormLabel>
                <RadioGroup onChange={onChange} row value={value}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    sx={{ flex: 1 }}
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    sx={{ flex: 1 }}
                    value={false}
                  />
                </RadioGroup>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="is_pacifier"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <FormLabel error={!!error}>
                  Is the child still using a pacifier?
                </FormLabel>
                <RadioGroup onChange={onChange} row value={value}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    sx={{ flex: 1 }}
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    sx={{ flex: 1 }}
                    value={false}
                  />
                </RadioGroup>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="is_solid_food"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <FormLabel error={!!error}>
                  Can your child eat solid foods?
                </FormLabel>
                <RadioGroup onChange={onChange} row value={value}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    sx={{ flex: 1 }}
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    sx={{ flex: 1 }}
                    value={false}
                  />
                </RadioGroup>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="supplement"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Supplement"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="breast_milk_schedule"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TimePicker
                inputRef={ref}
                label="Breast Milk Schedule"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="breast_milk_position"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Breast Milk Position"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="burp_position"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Burp Position"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="eat_schedule"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TimePicker
                inputRef={ref}
                label="Eat Schedule"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="allergy"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Allergy"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="favorite_food"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Favorite Food"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="disliked_food"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Disliked Food"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default FoodForm;

import * as z from "zod";

export const visitorFormSchema = z.object({
  child_dob: z
    .date({
      message: "Child dob is required",
    })
    .refine((date) => date <= new Date(), {
      message: "Date cannot be in the future",
    }),
  child_name: z.string().min(1, { message: "Child name is required" }),
  father_name: z.string().min(1, { message: "Father name is required" }),
  mother_name: z.string().min(1, { message: "Mother name is required" }),
});

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Divider,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect } from "react";
import VisitorFormContext from "../../contexts/VisitorFormContextProvider";
import ChildForm from "./ChildForm";
import ChoosePackage from "./ChoosePackage";
import DiaperForm from "./DiaperForm";
import EmergencyForm from "./EmergencyForm";
import FatherForm from "./FatherForm";
import FoodForm from "./FoodForm";
import HealthForm from "./HealthForm";
import MotherForm from "./MotherForm";
import Payment from "./Payment";
import RelationshipForm from "./RelationshipForm";
import SleepForm from "./SleepForm";
import SocialEmotionalForm from "./SocialEmotionalForm/";
import "./Visitor.scss";
import VisitorForm from "./VisitorForm";

const Connector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: "calc(-50% + 22px)",
    right: "calc(50% + 22px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "hsla(48, 95%, 60%, 1)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "hsla(48, 95%, 60%, 1)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "hsla(48, 95%, 60%, 0.5)",
    borderLeftWidth: 3,
    borderTopWidth: 4,
    borderRadius: 1,
  },
}));

const StepIcon = (props) => {
  const { active, completed, icon } = props;

  return (
    <h3
      className={`form__number ${!active ? "form__number--inactive" : ""} ${
        completed ? "form__number--completed" : ""
      }`}
    >
      {completed ? (
        <FontAwesomeIcon
          className="form__icon form__icon--check"
          icon={faCheck}
        />
      ) : (
        icon.toString().padStart(2, "0")
      )}
    </h3>
  );
};

const steps = [
  "Visitor Form",
  "Choose Package",
  "Payment",
  "Data Completion Form",
];

const Visitor = () => {
  const { maxStep, progress, setProgress, step } =
    useContext(VisitorFormContext);
  const theme = useTheme();
  const mqSm = useMediaQuery(theme.breakpoints.up("sm"));

  const StepperHorizontal = () => {
    return (
      <Stepper activeStep={step - 1} alternativeLabel connector={<Connector />}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={StepIcon}
                sx={{
                  "& .MuiStepLabel-label": {
                    fontFamily: "NunitoRegular",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };

  const StepperVertical = () => {
    return (
      <Stepper
        activeStep={step - 1}
        connector={<Connector />}
        orientation="vertical"
      >
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={StepIcon}
                sx={{
                  "& .MuiStepLabel-label": {
                    fontFamily: "NunitoRegular",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };

  const showStepForm = (index) => {
    if (index === 1) {
      return <VisitorForm />;
    } else if (index === 2) {
      return <ChoosePackage />;
    } else if (index === 3) {
      return <Payment />;
    } else if (index === 4) {
      return <ChildForm />;
    } else if (index === 5) {
      return <FatherForm />;
    } else if (index === 6) {
      return <MotherForm />;
    } else if (index === 7) {
      return <EmergencyForm />;
    } else if (index === 8) {
      return <RelationshipForm />;
    } else if (index === 9) {
      return <FoodForm />;
    } else if (index === 10) {
      return <SleepForm />;
    } else if (index === 11) {
      return <DiaperForm />;
    } else if (index === 12) {
      return <SocialEmotionalForm />;
    } else if (index === 13) {
      return <HealthForm />;
    } else if (index === 14) {
      return <h1>Done</h1>;
    }
  };

  useEffect(() => {
    const element = document.getElementById("visitor-content").offsetTop / 1.4;

    setProgress(Math.floor(((step - 1) / (maxStep - 1)) * 100));
    window.scrollTo({
      top: element,
      behavior: "smooth",
    });

    return () => {};
  }, [maxStep, setProgress, step]);

  return (
    <div className="visitor__container">
      <div className="visitor__stepper">
        {mqSm ? (
          <>
            <StepperHorizontal />
            <Divider flexItem orientation="vertical" sx={{ mx: 3 }} />
          </>
        ) : (
          <>
            <StepperVertical />
            <Divider sx={{ my: 3 }} />
          </>
        )}
        <div className="visitor__progress">
          <Box sx={{ position: "relative" }}>
            <CircularProgress
              size={70}
              sx={(theme) => ({
                color: theme.palette.grey[300],
                ...theme.applyStyles("dark", {
                  color: theme.palette.grey[800],
                }),
              })}
              thickness={8}
              value={100}
              variant="determinate"
            />
            <CircularProgress
              size={70}
              sx={{
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
                color: "hsl(84, 100%, 1%)",
                left: 0,
                position: "absolute",
              }}
              thickness={8}
              value={progress}
              variant="determinate"
            />
          </Box>
          <div className="visitor__progress-content">
            <p>Overall Progress</p>
            <p>
              <b>{progress}% Complete</b>
            </p>
          </div>
        </div>
      </div>
      <div className="visitor__content" id="visitor-content">
        {showStepForm(step)}
      </div>
    </div>
  );
};

export default Visitor;

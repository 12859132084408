import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";
import MyButton from "../../../components/MyButton";
import "./News.scss";

const News = () => {
  useGSAP(() => {
    ScrollTrigger.batch(".home-news__item", {
      end: "bottom center",
      start: "top 90%",
      once: true,
      onEnter: (batch) => {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 80 },
          {
            duration: 0.5,
            opacity: 1,
            stagger: 0.1,
            y: 0,
          }
        );
      },
    });
  });

  return (
    <section className="home-news__container">
      <h1>News</h1>
      <div className="home-news__content">
        <div className="home-news__item">
          <img alt="" src="https://images.alphacoders.com/129/1294486.png" />
          <div className="home-news__description">
            <p>1 Januari 2024</p>
            <h2>Lorem, ipsum.</h2>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae, consectetur?
            </p>
          </div>
        </div>
        <div className="home-news__item">
          <img alt="" src="https://images.alphacoders.com/781/781392.png" />
          <div className="home-news__description">
            <p>2 Agustus 2024</p>
            <h2>Lorem, ipsum.</h2>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae, consectetur?
            </p>
          </div>
        </div>
        <div className="home-news__item">
          <img alt="" src="https://images4.alphacoders.com/105/1056216.png" />
          <div className="home-news__description">
            <p>3 April 2024</p>
            <h2>Lorem, ipsum.</h2>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae, consectetur?
            </p>
          </div>
        </div>
        <div className="home-news__item">
          <img alt="" src="https://images7.alphacoders.com/115/1155199.jpg" />
          <div className="home-news__description">
            <p>4 November 2024</p>
            <h2>Lorem, ipsum.</h2>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae, consectetur?
            </p>
          </div>
        </div>
        <div className="home-news__item">
          <img alt="" src="https://images7.alphacoders.com/115/1155199.jpg" />
          <div className="home-news__description">
            <p>4 November 2024</p>
            <h2>Lorem, ipsum.</h2>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Recusandae, consectetur?
            </p>
          </div>
        </div>
      </div>
      <div className="home-news__button">
        <MyButton>See More</MyButton>
      </div>
    </section>
  );
};

export default News;

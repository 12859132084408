import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TimePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MyButton from "../../../components/MyButton/MyButton";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { sleepFormSchema } from "../../../schemas/visitorForm/sleepFormSchema";
import "./SleepForm.scss";

const SleepForm = () => {
  const { control, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      sleep_duration: "",
      sleep_location: "",
      sleep_time: null,
      is_cry: "",
    },
    resolver: zodResolver(sleepFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, sleep_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, sleep_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.sleep_form) {
      let tempKeys = Object.keys(data.sleep_form);
      let tempValues = Object.values(data.sleep_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="sleep-form__title">
        <h2 className="form__number form__number--big">04.g</h2>
        <h1>Sleep Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="sleep_time"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TimePicker
                inputRef={ref}
                label="Sleep Time"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="sleep_duration"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Sleep Duration"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="is_cry"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <FormLabel error={!!error}>
                  Does your child cry when sleeping?
                </FormLabel>
                <RadioGroup onChange={onChange} row value={value}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    sx={{ flex: 1 }}
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    sx={{ flex: 1 }}
                    value={false}
                  />
                </RadioGroup>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="sleep_location"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Sleep Location"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default SleepForm;

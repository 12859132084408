import React from "react";
import "./News.scss";
import Title from "./Title";

const News = () => {
  return (
    <section className="news">
      <Title />
      <div className="spacer"></div>
    </section>
  );
};

export default News;

import { useGSAP } from "@gsap/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LicenseInfo } from "@mui/x-license";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BrowserRouter } from "react-router-dom";
import "swiper/css";
import "swiper/css/bundle";
import "./App.scss";
import { HomeThemeContextProvider } from "./contexts/HomeThemeContextProvider";
import { VisitorFormContextProvider } from "./contexts/VisitorFormContextProvider";
import MyRoutes from "./routes/MyRoutes";

function App() {
  gsap.registerPlugin(useGSAP);
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);

  LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_KEY}`);

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <HomeThemeContextProvider>
          <VisitorFormContextProvider>
            <MyRoutes />
          </VisitorFormContextProvider>
        </HomeThemeContextProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;

import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import React from "react";
import daycare from "../../../assets/jsons/daycare.json";
import preschool from "../../../assets/jsons/preschool.json";
import "./MissionVision.scss";

const MissionVision = () => {
  useGSAP(() => {
    gsap.utils.toArray(".mission-vision__container").forEach((section) => {
      let tl = gsap.timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          anticipatePin: 1,
          end: () => "+=" + section.offsetWidth,
          scrub: 0.5,
          start: "center center",
          pin: true,
          trigger: section,
        },
      });

      tl.fromTo(
        section.querySelector(".mission-vision__vision"),
        { xPercent: 100, x: 0 },
        { xPercent: 0 }
      ).fromTo(
        section.querySelector(".mission-vision__vision > *"),
        { xPercent: -100, x: 0 },
        { xPercent: 0 },
        0
      );
    });
  });

  return (
    <section className="mission-vision__container">
      <div className="mission-vision__mission">
        <div>
          <Lottie
            className="mission-vision__animation"
            animationData={daycare}
            loop={true}
          />
          <div className="mission-vision__content">
            <h2>Our Mission</h2>
            <ul>
              <li>Lorem ipsum dolor sit amet.</li>
              <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
              <li>Lorem, ipsum dolor.</li>
              <li>Lorem ipsum dolor sit.</li>
              <li>Lorem ipsum dolor sit amet consectetur.</li>
              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mission-vision__vision">
        <div>
          <Lottie
            className="mission-vision__animation"
            animationData={preschool}
            loop={true}
          />
          <div className="mission-vision__content">
            <h2>Our Vision</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus,
              aspernatur!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;

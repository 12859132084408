import Lottie from "lottie-react";
import React from "react";
import "./Empty.scss";
import emptyJSON from "./emptyJSON.json";

const Empty = () => {
  return (
    <div className="empty__container">
      <Lottie animationData={emptyJSON} loop={true} />
      <p className="empty__text">No Data</p>
    </div>
  );
};

export default Empty;

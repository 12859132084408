import { zodResolver } from "@hookform/resolvers/zod";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MyButton from "../../../components/MyButton/MyButton";
import { bloodTypeOptions } from "../../../constants/bloodTypeOptions";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { healthFormSchema } from "../../../schemas/visitorForm/healthFormSchema";
import "./HealthForm.scss";

const HealthForm = () => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      allergy_symptoms: "",
      blood_type: "",
      growth_problem: "",
      health_problem: "",
      immunization_description: "",
      immunization_file: null,
      injuries: "",
      is_immunized: "",
      medication: "",
      special_treatment: "",
    },
    resolver: zodResolver(healthFormSchema),
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);
  const [isAgree, setIsAgree] = useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const handleNext = (payload) => {
    if (!isAgree) {
      snackbarContext.handleOpenSnackbar(
        "Please accept our agreement",
        "error"
      );
      return false;
    }

    setData({ ...data, diaper_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, diaper_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.diaper_form) {
      let tempKeys = Object.keys(data.diaper_form);
      let tempValues = Object.values(data.diaper_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form className="health-form" onSubmit={handleSubmit(handleNext)}>
      <div className="health-form__title">
        <h2 className="form__number form__number--big">04.j</h2>
        <h1>Health Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={{ sm: 6, xs: 12 }}>
          <Controller
            control={control}
            name="blood_type"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <FormControl error={!!error} fullWidth>
                <InputLabel error={!!error}>Blood Type</InputLabel>
                <Select
                  inputRef={ref}
                  label="Blood Type"
                  onChange={onChange}
                  value={value}
                >
                  {bloodTypeOptions.map((bloodType, index) => {
                    return (
                      <MenuItem key={index} value={bloodType.id}>
                        {bloodType.id === "" ? (
                          <i>{bloodType.label}</i>
                        ) : (
                          bloodType.label
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            control={control}
            name="is_immunized"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <FormLabel error={!!error}>
                  Is Child is regularly immunized?
                </FormLabel>
                <RadioGroup onChange={onChange} row value={value}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    sx={{ flex: 1 }}
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="No"
                    sx={{ flex: 1 }}
                    value={false}
                  />
                </RadioGroup>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Please explain the reason your child isn’t regularly immunized
          </h3>
          <Controller
            control={control}
            name="immunization_description"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                minRows={3}
                multiline
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <label htmlFor="immunization_file">
            <p>Upload Immunization Document</p>
            <br />
          </label>
          <input
            {...register("immunization_file")}
            accept="image/*"
            id="immunization_file"
            type="file"
          />
          <p className="file-upload__error-message">
            {errors?.immunization_file?.message}
          </p>
        </Grid>
        <Grid size={12}>
          <h3>Please Write other health problem your child has experienced</h3>
          <Controller
            control={control}
            name="health_problem"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Please mention any accidents and injuries your child has suffered
          </h3>
          <Controller
            control={control}
            name="injuries"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Please mention any accidents and injuries your child has suffered
          </h3>
          <Controller
            control={control}
            name="allergy_symptoms"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Please mention any medications and explain in what condition the
            medications are administered
          </h3>
          <Controller
            control={control}
            name="medication"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Do you have any concerns about your child growth? please describe
            your concern
          </h3>
          <Controller
            control={control}
            name="growth_problem"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <h3>
            Provide information about your child's health condition or other
            special needs that your child's caregiver should pay attention to
          </h3>
          <Controller
            control={control}
            name="special_treatment"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label=""
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            control={<Checkbox />}
            label="I agree that I'm telling the truth about my confidential data and I'm responsible to my own data"
            onChange={setIsAgree}
            value={isAgree}
          />
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default HealthForm;

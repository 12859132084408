import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import MyButton from "../../../components/MyButton/MyButton";
import { relationOptions } from "../../../constants/relationOptions";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { relationshipFormSchema } from "../../../schemas/visitorForm/relationshipFormSchema";
import "./RelationshipForm.scss";

const siblingInitialValues = {
  name: "",
  dob: null,
};

const relativeInitialValues = {
  name: "",
  relationship: "",
};

const RelationshipForm = () => {
  const { control, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      sibling: [siblingInitialValues],
      relative: [relativeInitialValues],
    },
    resolver: zodResolver(relationshipFormSchema),
  });
  const {
    append: siblingAppend,
    fields: siblingFields,
    remove: siblingRemove,
  } = useFieldArray({
    control,
    name: "sibling",
  });
  const {
    append: relativeAppend,
    fields: relativeFields,
    remove: relativeRemove,
  } = useFieldArray({
    control,
    name: "relative",
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, relationship_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, relationship_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.relationship_form) {
      let tempKeys = Object.keys(data.relationship_form);
      let tempValues = Object.values(data.relationship_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form className="relationship-form" onSubmit={handleSubmit(handleNext)}>
      <div className="relationship-form__title">
        <h2 className="form__number form__number--big">04.e</h2>
        <h1>Relationship Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={12}>
          <h3>Siblings</h3>
        </Grid>
        {siblingFields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              {index > 0 && (
                <>
                  <Grid size={12}>
                    <Divider textAlign="right">
                      <Chip
                        color="error"
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => {
                          siblingRemove(index);
                        }}
                        variant="outlined"
                      />
                    </Divider>
                  </Grid>
                </>
              )}
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`sibling.${index}.name`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Sibling Name"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`sibling.${index}.dob`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      format="dd-MM-yyyy"
                      inputRef={ref}
                      label="Sibling Date of Birth"
                      onChange={onChange}
                      slotProps={{
                        textField: {
                          error: !!error,
                          fullWidth: true,
                          helperText: error?.message,
                        },
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid size={12}>
          <Stack direction="row" justifyContent="center">
            <MyButton onClick={() => siblingAppend(siblingInitialValues)}>
              Add new
            </MyButton>
          </Stack>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <h3>In-House Relatives</h3>
        </Grid>
        {relativeFields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              {index > 0 && (
                <>
                  <Grid size={12}>
                    <Divider textAlign="right">
                      <Chip
                        color="error"
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => {
                          relativeRemove(index);
                        }}
                        variant="outlined"
                      />
                    </Divider>
                  </Grid>
                </>
              )}
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`relative.${index}.name`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Pick Up Person Name"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`relative.${index}.relationship`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <FormControl error={!!error} fullWidth>
                      <InputLabel error={!!error}>Relationship</InputLabel>
                      <Select
                        inputRef={ref}
                        label="Relationship"
                        onChange={onChange}
                        value={value}
                      >
                        {relationOptions.map((relation, index) => {
                          return (
                            <MenuItem key={index} value={relation.id}>
                              {relation.id === "" ? (
                                <i>{relation.label}</i>
                              ) : (
                                relation.label
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid size={12}>
          <Stack direction="row" justifyContent="center">
            <MyButton onClick={() => relativeAppend(relativeInitialValues)}>
              Add new
            </MyButton>
          </Stack>
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default RelationshipForm;

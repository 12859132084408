import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TimePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import MyButton from "../../../../components/MyButton/MyButton";
import MyButtonInverted from "../../../../components/MyButtonInverted";
import { dayNames } from "../../../../constants/dayNames";
import { homeEducareDurationOptions } from "../../../../constants/homeEducare/homeEducareDurationOptions";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import VisitorFormContext from "../../../../contexts/VisitorFormContextProvider";
import { homeEducareScheduleSchema } from "../../../../schemas/visitorForm/homeEducareScheduleSchema";
import "./HomeEducare.scss";

const initialValues = [
  {
    duration: "",
    is_checked: false,
    start_time: null,
  },
  {
    duration: "",
    is_checked: false,
    start_time: null,
  },
  {
    duration: "",
    is_checked: false,
    start_time: null,
  },
  {
    duration: "",
    is_checked: false,
    start_time: null,
  },
  {
    duration: "",
    is_checked: false,
    start_time: null,
  },
  {
    duration: "",
    is_checked: false,
    start_time: null,
  },
  {
    duration: "",
    is_checked: false,
    start_time: null,
  },
];

const HomeEducare = ({ handleNext, handlePrev }) => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      home_educare_schedule: initialValues,
    },
    resolver: zodResolver(homeEducareScheduleSchema),
  });
  const { fields } = useFieldArray({
    control,
    name: "home_educare_schedule",
  });
  const { data } = useContext(VisitorFormContext);
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const handleChooseHomeEducareSchedule = (payload) => {
    handleNext(payload);
  };

  const handleError = (error) => {
    let tempError = error.home_educare_schedule.day_list;

    if (tempError) {
      snackbarContext.handleOpenSnackbar(tempError.message, "error");
    }
  };

  useEffect(() => {
    if (data.home_educare_schedule) {
      setValue("home_educare_schedule", data.home_educare_schedule);
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="home-educare__title">
        <h2 className="form__number form__number--big">02.a</h2>
        <h1>Choose Schedule</h1>
        <MyButtonInverted
          onClick={() => {
            dialogContext.handleOpenDialog(
              () => reset(),
              "Are you sure want to reset?",
              "Reset Confirmation"
            );
          }}
        >
          Reset
        </MyButtonInverted>
      </div>
      <form
        onSubmit={handleSubmit(handleChooseHomeEducareSchedule, handleError)}
      >
        <Grid container spacing={3}>
          {fields.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <Grid
                  className="home-educare__card"
                  container
                  size={12}
                  spacing={3}
                >
                  <label htmlFor={dayNames[index]}></label>
                  <input
                    {...register(`home_educare_schedule.${index}.is_checked`, {
                      onChange: (e) => {
                        if (isSubmitted) {
                          trigger([
                            `home_educare_schedule.${index}.duration`,
                            `home_educare_schedule.${index}.start_time`,
                          ]);
                        }
                        return e.target.value;
                      },
                    })}
                    id={dayNames[index]}
                    type="checkbox"
                  />
                  <Grid size={{ sm: 4, xs: 12 }}>
                    <h2 className="home-educare__day">{dayNames[index]}</h2>
                  </Grid>
                  <Grid size={{ sm: 4, xs: 12 }}>
                    <Controller
                      control={control}
                      name={`home_educare_schedule.${index}.duration`}
                      render={({
                        field: { onChange, ref, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <InputLabel error={!!error}>Duration</InputLabel>
                          <Select
                            inputRef={ref}
                            label="Duration"
                            onChange={onChange}
                            value={value}
                          >
                            {homeEducareDurationOptions.map(
                              (duration, index) => {
                                return (
                                  <MenuItem key={index} value={duration.id}>
                                    {duration.id === "" ? (
                                      <i>{duration.label}</i>
                                    ) : (
                                      duration.label
                                    )}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid size={{ sm: 4, xs: 12 }}>
                    <Controller
                      control={control}
                      name={`home_educare_schedule.${index}.start_time`}
                      render={({
                        field: { onChange, ref, value },
                        fieldState: { error },
                      }) => (
                        <TimePicker
                          inputRef={ref}
                          label="Start Time"
                          onChange={onChange}
                          slotProps={{
                            textField: {
                              error: !!error,
                              fullWidth: true,
                              helperText: error?.message,
                            },
                          }}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
          <MyButton
            onClick={() =>
              handlePrev({
                home_educare_schedule: getValues("home_educare_schedule"),
              })
            }
          >
            Prev
          </MyButton>
          <MyButton>Next</MyButton>
        </Stack>
      </form>
    </>
  );
};

export default HomeEducare;

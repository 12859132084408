import { Divider, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext } from "react";
import MyButton from "../../../components/MyButton/MyButton";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import "./Payment.scss";

const Payment = () => {
  const { setStep } = useContext(VisitorFormContext);

  const handleNext = () => {
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setStep((prevState) => prevState - 1);
  };

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <div className="payment__title">
          <h3 className="form__number">03</h3>
          <h1>Payment</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
            consequuntur est eveniet, iste magnam eius dolore repudiandae non
            quisquam pariatur?
          </p>
        </div>
      </Grid>
      <Grid size={12}>
        <div className="payment__box">
          <div className="payment__detail">
            <h2>Details</h2>
            <h2>Price</h2>
          </div>
          <div className="payment__detail">
            <h3>Daycare</h3>
            <p>Rp 1.500.000,00</p>
          </div>
          <div className="payment__detail">
            <h3>Catering</h3>
            <p>Rp 50.000,00</p>
          </div>
          <div className="payment__detail">
            <h3>Learning Center</h3>
            <p>Rp 15.00.000,00</p>
          </div>
        </div>
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={12}>
        <div className="payment__price">
          <div className="payment__detail">
            <h2>Subtotal</h2>
            <p>Rp 1.550.000,00</p>
          </div>
          <div className="payment__detail">
            <h2>PPN</h2>
            <p>Rp 50.000,00</p>
          </div>
          <div className="payment__detail">
            <h2>Grand Total</h2>
            <p>
              <b>Rp 1.600.000,00</b>
            </p>
          </div>
        </div>
      </Grid>
      <Grid size={12}>
        <div className="payment__title">
          <h3 className="form__number form__number--big">03.a</h3>
          <h1>Tutorial</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. In, omnis!
          </p>
        </div>
      </Grid>
      <Grid size={12}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
        eveniet?
      </Grid>
      <Grid size={12}>
        <Stack direction="row" justifyContent="space-between">
          <MyButton onClick={handlePrev}>Prev</MyButton>
          <MyButton onClick={handleNext}>Next</MyButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Payment;

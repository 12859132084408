import { zodResolver } from "@hookform/resolvers/zod";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { loginSchema } from "../../schemas/loginSchema";
import "./Login.scss";

const Login = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(loginSchema),
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonLogin: false,
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleLogin = (payload) => {
    setIsLoadingButtonState((prevState) => ({
      ...prevState,
      buttonLogin: true,
    }));

    setIsLoadingButtonState((prevState) => ({
      ...prevState,
      buttonLogin: false,
    }));
  };

  return (
    <section className="login__container">
      <img alt="" src="https://images.alphacoders.com/129/1294486.png" />
      <div className="login__overlay"></div>
      <div className="login__box">
        <form onSubmit={handleSubmit(handleLogin)}>
          <h1>Sign In</h1>
          <Grid container spacing={3}>
            <Grid size={12}>
              <Controller
                control={control}
                name="email"
                render={({
                  field: { onChange, ref, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    inputRef={ref}
                    label="Email"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid size={12}>
              <Controller
                control={control}
                name="password"
                render={({
                  field: { onChange, ref, value },
                  fieldState: { error },
                }) => (
                  <FormControl error={!!error} fullWidth>
                    <InputLabel error={!!error}>Password</InputLabel>
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            edge="end"
                          >
                            {isShowPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputRef={ref}
                      label="Password"
                      onChange={onChange}
                      type={isShowPassword ? "text" : "password"}
                      value={value}
                    />
                    <FormHelperText error>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid size={12}>
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  endIcon={<LoginIcon />}
                  fullWidth
                  loading={isLoadingButtonState.buttonLogin}
                  loadingPosition="end"
                  type="submit"
                >
                  Sign In
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
          <p className="login__note">
            Are you new here? &nbsp;
            <NavLink to="/visitor">
              <strong>Join Us</strong>
            </NavLink>
          </p>
        </form>
      </div>
    </section>
  );
};

export default Login;

import * as z from "zod";

const maxFileSizeMB = 5;
const allowedFileExtensions = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const healthFormSchema = z.object({
  allergy_symptoms: z
    .string()
    .min(1, { message: "Allergy Symptoms is required" }),
  blood_type: z.string().min(1, { message: "Blood type is required" }),
  growth_problem: z.string().min(1, { message: "Growth problem is required" }),
  health_problem: z.string().min(1, { message: "Health problem is required" }),
  immunization_description: z
    .string()
    .min(1, { message: "Immunization description is required" }),
  immunization_file: z
    .instanceof(FileList)
    .transform((files) => Array.from(files))
    .refine((files) => files.length > 0, {
      message: "At least one file is required",
    })
    .refine(
      (files) =>
        files.every((file) => file.size <= maxFileSizeMB * 1024 * 1024),
      {
        message: "File must be 5MB or less",
      }
    )
    .refine(
      (files) =>
        files.every((file) => allowedFileExtensions.includes(file.type)),
      {
        message: "Only .jpeg, .jpg, .png, and .webp files are allowed",
      }
    ),
  injuries: z.string().min(1, { message: "Injuries is required" }),
  is_immunized: z.string().min(1, { message: "This field is required" }),
  medication: z.string().min(1, { message: "Medication is required" }),
  special_treatment: z
    .string()
    .min(1, { message: "Special treatment is required" }),
});

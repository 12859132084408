export const globalTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1280,
      lg: 1920,
      xl: 2560,
    },
  },
  common: {
    black: "hsl(84, 100%, 1%)",
    white: "hsl(210, 17%, 98%)",
  },
  components: {
    MuiLoadingButton: {
      defaultProps: {
        color: "secondary",
        variant: "contained",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
  shape: {
    borderRadius: 15,
  },
};

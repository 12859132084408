import { createContext, useState } from "react";

const VisitorFormContext = createContext();

const VisitorFormContextProvider = ({ children }) => {
  const maxStep = 14;
  const [data, setData] = useState({});
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(1);

  return (
    <VisitorFormContext.Provider
      value={{ data, setData, maxStep, progress, setProgress, step, setStep }}
    >
      {children}
    </VisitorFormContext.Provider>
  );
};

export { VisitorFormContextProvider };
export default VisitorFormContext;

import { zodResolver } from "@hookform/resolvers/zod";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import MyButton from "../../../../components/MyButton/MyButton";
import MyButtonInverted from "../../../../components/MyButtonInverted";
import { lessonOptions } from "../../../../constants/lessonOptions";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import VisitorFormContext from "../../../../contexts/VisitorFormContextProvider";
import { learningCenterScheduleSchema } from "../../../../schemas/visitorForm/learningCenterScheduleSchema";
import "./LearningCenter.scss";

const dayOptions = [
  {
    id: "1",
    label: "Senin, 15:30 - 16:30",
  },
  {
    id: "2",
    label: "Selasa, 15:30 - 16:30",
  },
  {
    id: "3",
    label: "Rabu, 15:30 - 16:30",
  },
  {
    id: "4",
    label: "Kamis, 15:30 - 16:30",
  },
];

const initialValues = [
  {
    day_list: [],
    is_checked: false,
  },
  {
    day_list: [],
    is_checked: false,
  },
  {
    day_list: [],
    is_checked: false,
  },
  {
    day_list: [],
    is_checked: false,
  },
  {
    day_list: [],
    is_checked: false,
  },
  {
    day_list: [],
    is_checked: false,
  },
];

const LearningCenter = ({ handleNext, handlePrev }) => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      learning_center_schedule: initialValues,
    },
    resolver: zodResolver(learningCenterScheduleSchema),
  });
  const { fields } = useFieldArray({
    control,
    name: "learning_center_schedule",
  });
  const { data } = useContext(VisitorFormContext);
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const handleChooseLearningCenterSchedule = (payload) => {
    handleNext(payload);
  };

  const handleError = (error) => {
    let tempError = error.learning_center_schedule.lesson_list;

    if (tempError) {
      snackbarContext.handleOpenSnackbar(tempError.message, "error");
    }
  };

  useEffect(() => {
    if (data.learning_center_schedule) {
      setValue("learning_center_schedule", data.learning_center_schedule);
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="learning-center__title">
        <h2 className="form__number form__number--big">02.a</h2>
        <h1>Choose Schedule</h1>
        <MyButtonInverted
          onClick={() => {
            dialogContext.handleOpenDialog(
              () => reset(),
              "Are you sure want to reset?",
              "Reset Confirmation"
            );
          }}
        >
          Reset
        </MyButtonInverted>
      </div>
      <form
        onSubmit={handleSubmit(handleChooseLearningCenterSchedule, handleError)}
      >
        <Grid container spacing={3}>
          {fields.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <Grid
                  className="learning-center__card"
                  container
                  size={12}
                  spacing={3}
                >
                  <label htmlFor={lessonOptions[index]}></label>
                  <input
                    {...register(
                      `learning_center_schedule.${index}.is_checked`,
                      {
                        onChange: (e) => {
                          if (isSubmitted) {
                            trigger(
                              `learning_center_schedule.${index}.day_list`
                            );
                          }
                          return e.target.value;
                        },
                      }
                    )}
                    id={lessonOptions[index]}
                    type="checkbox"
                  />
                  <Grid size={{ sm: 4, xs: 12 }}>
                    <h2 className="learning-center__day">
                      {lessonOptions[index]}
                    </h2>
                  </Grid>
                  <Grid size={{ sm: 8, xs: 12 }}>
                    <Controller
                      control={control}
                      name={`learning_center_schedule.${index}.day_list`}
                      render={({
                        field: { onChange, ref, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          limitTags={3}
                          multiple
                          onChange={(_, newValue) => onChange(newValue)}
                          options={dayOptions}
                          renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={key} {...optionProps}>
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error}
                              helperText={error?.message}
                              inputRef={ref}
                              label="Choose day"
                            />
                          )}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
          <MyButton
            onClick={() =>
              handlePrev({
                learning_center_schedule: getValues("learning_center_schedule"),
              })
            }
          >
            Prev
          </MyButton>
          <MyButton>Next</MyButton>
        </Stack>
      </form>
    </>
  );
};

export default LearningCenter;

import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import { Chip, Divider, InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Stack } from "@mui/system";
import React, { useContext, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import MyButton from "../../../components/MyButton/MyButton";
import VisitorFormContext from "../../../contexts/VisitorFormContextProvider";
import { emergencyFormSchema } from "../../../schemas/visitorForm/emergencyFormSchema";
import "./EmergencyForm.scss";

const emergencyPersonInitialValues = {
  ktp: null,
  name: "",
  office_phone_number: "",
  phone_number: "",
  relationship: "",
};

const pickUpPersonInitialValues = { ktp: null, name: "", phone_number: "" };

const EmergencyForm = () => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emergency_person: [emergencyPersonInitialValues],
      pick_up_person: [pickUpPersonInitialValues],
    },
    resolver: zodResolver(emergencyFormSchema),
  });
  const {
    append: emergencyPersonAppend,
    fields: emergencyPersonFields,
    remove: emergencyPersonRemove,
  } = useFieldArray({
    control,
    name: "emergency_person",
  });
  const {
    append: pickUpPersonAppend,
    fields: pickUpPersonFields,
    remove: pickUpPersonRemove,
  } = useFieldArray({
    control,
    name: "pick_up_person",
  });
  const { data, setData, setStep } = useContext(VisitorFormContext);

  const handleNext = (payload) => {
    setData({ ...data, emergency_form: { ...payload } });
    setStep((prevState) => prevState + 1);
  };

  const handlePrev = () => {
    setData({ ...data, emergency_form: { ...getValues() } });
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (data.emergency_form) {
      let tempKeys = Object.keys(data.emergency_form);
      let tempValues = Object.values(data.emergency_form);

      for (let i = 0; i < tempKeys.length; i++) {
        setValue(tempKeys[i], tempValues[i]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <form className="emergency-form" onSubmit={handleSubmit(handleNext)}>
      <div className="emergency-form__title">
        <h2 className="form__number form__number--big">04.d</h2>
        <h1>Emergency Form</h1>
      </div>
      <Grid container spacing={3}>
        <Grid size={12}>
          <h3>Emergency Person</h3>
        </Grid>
        {emergencyPersonFields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              {index > 0 && (
                <>
                  <Grid size={12}>
                    <Divider textAlign="right">
                      <Chip
                        color="error"
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => {
                          emergencyPersonRemove(index);
                        }}
                        variant="outlined"
                      />
                    </Divider>
                  </Grid>
                </>
              )}
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`emergency_person.${index}.name`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Emergency Person Name"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`emergency_person.${index}.relationship`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Relationship"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`emergency_person.${index}.phone_number`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <NumericFormat
                      allowNegative={false}
                      customInput={TextField}
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Phone Number"
                      onValueChange={(values) => {
                        onChange(values.value);
                      }}
                      slotProps={{
                        input: {
                          inputMode: "numeric",
                          startAdornment: (
                            <InputAdornment position="start">
                              +62
                            </InputAdornment>
                          ),
                        },
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`emergency_person.${index}.office_phone_number`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <NumericFormat
                      allowNegative={false}
                      customInput={TextField}
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Office Phone Number"
                      onValueChange={(values) => {
                        onChange(values.value);
                      }}
                      slotProps={{
                        input: {
                          inputMode: "numeric",
                          startAdornment: (
                            <InputAdornment position="start">
                              +62
                            </InputAdornment>
                          ),
                        },
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={12}>
                <label htmlFor={`emergency_person.${index}.ktp`}>
                  <p>Upload KTP</p>
                  <br />
                </label>
                <input
                  {...register(`emergency_person.${index}.ktp`)}
                  accept="image/*"
                  id={`emergency_person.${index}.ktp`}
                  type="file"
                />
                <p className="file-upload__error-message">
                  {errors?.emergency_person?.[index]?.ktp?.message}
                </p>
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid size={12}>
          <Stack direction="row" justifyContent="center">
            <MyButton
              onClick={() =>
                emergencyPersonAppend(emergencyPersonInitialValues)
              }
            >
              Add new
            </MyButton>
          </Stack>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <h3>Pick Up Person</h3>
        </Grid>
        {pickUpPersonFields.map((field, index) => {
          return (
            <React.Fragment key={field.id}>
              {index > 0 && (
                <>
                  <Grid size={12}>
                    <Divider textAlign="right">
                      <Chip
                        color="error"
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => {
                          pickUpPersonRemove(index);
                        }}
                        variant="outlined"
                      />
                    </Divider>
                  </Grid>
                </>
              )}
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`pick_up_person.${index}.name`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Pick Up Person Name"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ sm: 6, xs: 12 }}>
                <Controller
                  control={control}
                  name={`pick_up_person.${index}.phone_number`}
                  render={({
                    field: { onChange, ref, value },
                    fieldState: { error },
                  }) => (
                    <NumericFormat
                      allowNegative={false}
                      customInput={TextField}
                      error={!!error}
                      fullWidth
                      helperText={error?.message}
                      inputRef={ref}
                      label="Phone Number"
                      onValueChange={(values) => {
                        onChange(values.value);
                      }}
                      slotProps={{
                        input: {
                          inputMode: "numeric",
                          startAdornment: (
                            <InputAdornment position="start">
                              +62
                            </InputAdornment>
                          ),
                        },
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid size={12}>
                <label htmlFor={`pick_up_person.${index}.ktp`}>
                  <p>Upload KTP</p>
                  <br />
                </label>
                <input
                  {...register(`pick_up_person.${index}.ktp`)}
                  accept="image/*"
                  id={`pick_up_person.${index}.ktp`}
                  type="file"
                />
                <p className="file-upload__error-message">
                  {errors?.pick_up_person?.[index]?.ktp?.message}
                </p>
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid size={12}>
          <Stack direction="row" justifyContent="center">
            <MyButton
              onClick={() => pickUpPersonAppend(pickUpPersonInitialValues)}
            >
              Add new
            </MyButton>
          </Stack>
        </Grid>
        <Grid size={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <MyButton onClick={() => handlePrev()}>Prev</MyButton>
            <MyButton>Next</MyButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmergencyForm;

import React from "react";
import "./Contact.scss";
import Location from "./Location";
import Question from "./Question";
import Title from "./Title";

const Contact = () => {
  return (
    <section className="contact">
      <Title />
      <div className="spacer"></div>
      <Question />
      <div className="spacer"></div>
      <Location />
      <div className="spacer"></div>
    </section>
  );
};

export default Contact;

import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "../components/NotFound";
import HomeLayout from "../layouts/HomeLayout";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Login from "../pages/Login";
import News from "../pages/News";
import Register from "../pages/Register";
import Visitor from "../pages/Visitor";

const MyRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });

    return () => {};
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<HomeLayout />} path="/">
        <Route element={<About />} path="/about" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Home />} path="" />
        <Route element={<Login />} path="/login" />
        <Route element={<News />} path="/news" />
        <Route element={<Register />} path="/register" />
        <Route element={<Visitor />} path="/visitor" />
      </Route>
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MyRoutes;

import * as z from "zod";

export const foodFormSchema = z.object({
  allergy: z.string().min(1, { message: "Allergy is required" }),
  breast_milk_position: z
    .string()
    .min(1, { message: "Breast milk position is required" }),
  breast_milk_schedule: z.date(),
  burp_position: z.string().min(1, { message: "Burp position is required" }),
  disliked_food: z.string().min(1, { message: "Disliked food is required" }),
  eat_schedule: z.date(),
  favorite_food: z.string().min(1, { message: "Favorite food is required" }),
  is_breast_milk: z.string().min(1, { message: "This field is required" }),
  is_pacifier: z.string().min(1, { message: "This field is required" }),
  is_planning_breast_milk: z
    .string()
    .min(1, { message: "This field is required" }),
  is_solid_food: z.string().min(1, { message: "This field is required" }),
  supplement: z.string().min(1, { message: "Supplement is required" }),
});

import { ThemeProvider } from "@mui/material";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { DialogContextProvider } from "../../contexts/DialogContextProvider";
import HomeThemeContext from "../../contexts/HomeThemeContextProvider";
import { SnackbarContextProvider } from "../../contexts/SnackbarContextProvider";

const HomeLayout = () => {
  const { theme } = useContext(HomeThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <DialogContextProvider>
        <SnackbarContextProvider>
          <Navbar />
          <Outlet />
          <Footer />
        </SnackbarContextProvider>
      </DialogContextProvider>
    </ThemeProvider>
  );
};

export default HomeLayout;

import * as z from "zod";

const maxFileSizeMB = 5;
const allowedFileExtensions = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const motherFormSchema = z.object({
  employment_duration: z.string().min(1, {
    message: "Employment duration is required",
  }),
  full_name: z.string().min(1, { message: "Full name is required" }),
  home_address: z.string().min(1, { message: "Address is required" }),
  job_title: z.string().min(1, { message: "Job title is required" }),
  ktp: z
    .instanceof(FileList)
    .transform((files) => Array.from(files))
    .refine((files) => files.length > 0, {
      message: "At least one file is required",
    })
    .refine(
      (files) =>
        files.every((file) => file.size <= maxFileSizeMB * 1024 * 1024),
      {
        message: "File must be 5MB or less",
      }
    )
    .refine(
      (files) =>
        files.every((file) => allowedFileExtensions.includes(file.type)),
      {
        message: "Only .jpeg, .jpg, .png, and .webp files are allowed",
      }
    ),
  office_address: z.string().min(1, { message: "Office address is required" }),
  office_name: z.string().min(1, { message: "Office name is required" }),
  office_phone_number: z
    .string()
    .min(1, { message: "Office phone Number is required" }),
  phone_number: z.string().min(1, { message: "Phone number is required" }),
  religion: z.string().min(1, { message: "Religion is required" }),
  salary: z.string().min(1, { message: "Estimated salary is required" }),
});

import React from "react";
import "./Preschool.scss";

const Preschool = () => {
  return (
    <>
      <div className="preschool__title">
        <h2 className="form__number form__number--big">02.a</h2>
        <h1>Welcome to Preschool</h1>
      </div>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vitae expedita
        nemo earum ipsam quisquam. Voluptate cumque veritatis optio eligendi
        tempore corporis quasi eius voluptates provident ipsam alias a, aut
        vitae distinctio, nostrum id quisquam nobis quos? Ullam maiores, maxime
        vitae molestias commodi aliquid. Accusantium cum esse optio magnam
        tenetur quia.
      </p>
    </>
  );
};

export default Preschool;

import React from "react";
import "./About.scss";
import Facility from "./Facility";
import Milestone from "./Milestone";
import MissionVision from "./MissionVision";
import Title from "./Title";

const About = () => {
  return (
    <section className="about">
      <Title />
      <MissionVision />
      <div className="spacer"></div>
      <Milestone />
      <div className="spacer"></div>
      <Facility />
      <div className="spacer"></div>
    </section>
  );
};

export default About;
